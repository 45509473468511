div.toTop {
  position: fixed;
  bottom: 4rem;
  right: 3rem;
  opacity: 0.7;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
  display: none;

  &.active {
    display: block;
  }

  &:hover {
    opacity: 1;
    img {
      transform: rotate(-90deg) translateX(10px);
    }
  }

  img {
    transform: rotate(-90deg);
    width: 30px;
    transition: transform 0.3s ease-in;
  }
  span {
    display: block;
    color: $white;
    font-size: 1.3rem;
  }
}
