section.gape-strip {
  // >div {
  //     display: grid;
  //     grid-template-columns: repeat(4, 1fr);
  // }

  $animationSpeed: 40s;

  // Animation
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-200px * 6));
    }
  }
  @keyframes scrollMobile {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-300px * 6));
    }
  }
  @keyframes scrollTablet {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-400px * 6));
    }
  }

  // Styling
  .slider {
    // background: white;
    // box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    // height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100vw;

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(200px * 12);

      @media screen and (min-width: $mobile-BP) {
        animation: scrollMobile $animationSpeed linear infinite;
        width: calc(300px * 12);
      }

      @media screen and (min-width: $tablet-BP) {
        width: calc(400px * 12);
        animation: scrollTablet $animationSpeed linear infinite;
      }
    }

    .slide {
      height: 200px;
      width: 200px;

      @media screen and (min-width: $mobile-BP) {
        height: 300px;
        width: 300px;
      }

      @media screen and (min-width: $tablet-BP) {
        height: 400px;
        width: 400px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
