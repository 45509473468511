footer.main-footer {
  margin-top: 10rem;

  .container {
    max-width: 1600px;
    margin: 0 auto;
  }

  nav {
    text-align: center;
    margin-bottom: 4rem;
    a {
      color: $white;
      display: inline-block;
      margin: 0 1rem;
      font-size: 1.5rem;

      @media screen and (min-width: $mobile-BP) {
        margin: 0 3rem;
        font-size: 1.6rem;
      }
    }
  }

  img {
    width: 100%;
  }
}
