main.home {
  background-image: url(../../assets/starfield-2.png);
  background-size: contain;

  section.team {
    margin-top: 0;
    padding-top: 0;

    h2 {
      background-color: $aqua;
      padding: 4rem 0;
      color: $black;
    }
  }
}
